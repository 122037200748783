import { createSignal, createEffect, Accessor } from "solid-js";
import { getCurrentUILanguageCode } from '../SmallComponents/Translate'

type LanguageCode = "en" | "en-GB" | "da" | "nb" | "sv" | "pl" | "fr" | "du" | "de"| "es"| "it"| "fi"| "pt";


// Define a new interface for localized strings
interface LocalizedText {
    "en": string; // US
    "en-GB": string; // UK GB
    "da": string;   // Danish
    "nb": string;    // Norwegian Bokmål
    "sv": string;    // Swedish
    "pl": string;    // Polish
    "fr": string;    // French
    "du": string;    // Dutch
    "de": string;    // German
    "es": string;
    "it": string;
    "fi": string;
    "pt": string;
}

// Update the RatedByType interface
export interface UserRoleType {
    code: string;
    label: LocalizedText;
}
   

export const UserRoleList: UserRoleType[] = [
    {
        "code": "owner",
        "label": {
            "da": "Kontoejer",        // Danish
            "en": "Account Owner",    // English
            "en-GB": "Account Owner", // English (UK)
            "de": "Kontoinhaber",     // German
            "es": "Propietario de la Cuenta", // Spanish
            "fr": "Propriétaire du Compte",   // French
            "it": "Proprietario dell'Account",// Italian
            "du": "Rekeninghouder",   // Dutch
            "nb": "Kontoeier",        // Norwegian Bokmål
            "pl": "Właściciel Konta", // Polish
            "pt": "Proprietário da Conta",    // Portuguese
            "fi": "Tilin Omistaja",   // Finnish
            "sv": "Kontoinnehavare"   // Swedish
        }
    },    
    {  
        "code": "admin",
        "label": {
            "da": "Administrator",
            "en": "Administrator",
            "en-GB": "Administrator",
            "de": "Administrator",
            "es": "Administrador",
            "fr": "Administrateur",
            "it": "Amministratore",
            "du": "Beheerder",
            "nb": "Administrator",
            "pl": "Administrator",
            "pt": "Administrador",
            "fi": "Ylläpitäjä",
            "sv": "Administratör"
        }
    },
    {
        "code": "manager",
        "label": {
            "da": "Chef",
            "en": "Manager",
            "en-GB": "Manager",
            "de": "Manager",
            "es": "Gerente",
            "fr": "Gérant",
            "it": "Manager",
            "du": "Manager",
            "nb": "Leder",
            "pl": "Menedżer",
            "pt": "Gerente",
            "fi": "Johtaja",
            "sv": "Chef"
        }
    },
    {
        "code": "viewer",
        "label": {
            "da": "Seer",
            "en": "Viewer",
            "en-GB": "Viewer",
            "de": "Betrachter",
            "es": "Espectador",
            "fr": "Visionneur",
            "it": "Visualizzatore",
            "du": "Kijker",
            "nb": "Seer",
            "pl": "Widz",
            "pt": "Visualizador",
            "fi": "Katsoja",
            "sv": "Åskådare"
        }
    },
    {
        "code": "user",
        "label": {
            "da": "Bruger",
            "en": "User",
            "en-GB": "User",
            "de": "Benutzer",
            "es": "Usuario",
            "fr": "Utilisateur",
            "it": "Utente",
            "du": "Gebruiker",
            "nb": "Bruker",
            "pl": "Użytkownik",
            "pt": "Usuário",
            "fi": "Käyttäjä",
            "sv": "Användare"
        }
    },
    {
        "code": "recruiter",
        "label": {
            "da": "Rekrutter",
            "en": "Recruiter",
            "en-GB": "Recruiter",
            "de": "Rekrutierer",
            "es": "Reclutador",
            "fr": "Recruteur",
            "it": "Reclutatore",
            "du": "Werver",
            "nb": "Rekrutterer",
            "pl": "Rekruter",
            "pt": "Recrutador",
            "fi": "Rekrytoija",
            "sv": "Rekryterare"
        }
    },
    {
        "code": "legal",
        "label": {
            "da": "Juridisk",
            "en": "Legal",
            "en-GB": "Legal",
            "de": "Rechtlich",
            "es": "Legal",
            "fr": "Légal",
            "it": "Legale",
            "du": "Juridisch",
            "nb": "Juridisk",
            "pl": "Prawny",
            "pt": "Legal",
            "fi": "Laillinen",
            "sv": "Juridisk"
        }
    },
    {
        "code": "billing",
        "label": {
            "da": "Fakturering",
            "en": "Billing",
            "en-GB": "Billing",
            "de": "Abrechnung",
            "es": "Facturación",
            "fr": "Facturation",
            "it": "Fatturazione",
            "du": "Facturatie",
            "nb": "Fakturering",
            "pl": "Rozliczenie",
            "pt": "Faturamento",
            "fi": "Laskutus",
            "sv": "Fakturering"
        }
    }
    
]