
import { Typography, Button, ListItemText, ListItemIcon, Stack, Card, Box, Divider, } from "@suid/material";
import { Switch, FormControlLabel, DialogActions, Grid, Select, MenuItem, CardMedia } from "@suid/material";
import { SelectChangeEvent } from "@suid/material/Select";
import { createSignal, createEffect, Show, onMount, onCleanup } from 'solid-js';
import { getMobilePrefixFromCountryCode } from "../components/DataLists/DataCountryList";
import { createStore } from "solid-js/store";
import { AppBarWithLogo } from '../components/AppMenuBar/AppBarMenu';
import { ShowHeading } from '../components/SmallComponents/SmallHeader'
import { Tabs, Tab } from '../components/SmallComponents/SmallTabs';
import { CountrySelect } from '../components/SmallComponents/SmallFieldCountryCodeList';
import { PhoneNumberInput } from "../components/SmallComponents/SmallFieldPhoneNumber";
import { FieldTextValue } from "../components/SmallComponents/SmallFieldText";
import { FieldEmail} from "../components/SmallComponents/SmallFieldEmail";
import { FieldPassword} from "../components/SmallComponents/SmallFieldPassword";
import { getUserStore, setUserStore } from "../context/userContext"
import { IndustryList } from "../components/MediumComponents/IndustriesComponent";

import { UserRoleType, UserRoleList } from "../components/DataLists/DataUserRoleList";

import { UILanguageType, UILanguages } from "../components/DataLists/DataUILanguageList";
import { getText, getCurrentUILanguageCode } from '../components/SmallComponents/Translate'
import { showError } from '../components/SmallComponents/SmallShowError'


let checkIntervalId: number;

// This function is to check the session status
const checkSessionStatus = async (sessionId: string) => {
  const response = await fetch(`/api/checkSession/${sessionId}`);
  if (response.status === 200) {
    // session is confirmed
    return true;
  } else if (response.status === 404) {
    // session not found
    return false;
  } else {
    // other status, retry
    return false;
  }
};

/*
const MyComponent = () => {
  let confirmed = false;
  const sessionId: string = 'your-session-id'; // replace with your actual sessionId
  let totalElapsedTime = 0;

  const startInterval = (delay: number) => {
    checkIntervalId = window.setInterval(async () => {
      totalElapsedTime += delay;
      const result = await checkSessionStatus(sessionId);
      if (result === true || totalElapsedTime >= 15 * 60 * 1000) {
        // Session confirmed or timeout after 15 minutes
        window.clearInterval(checkIntervalId);
        confirmed = result;
      }
    }, delay);
  };

  // Run every 15 seconds for the first 4 minutes
  startInterval(15 * 1000);

  // After 4 minutes, run every 60 seconds
  setTimeout(() => {
    clearInterval(checkIntervalId);
    startInterval(60 * 1000);
  }, 4 * 60 * 1000);

  // Cleanup when component unmounts
  onCleanup(() => {
    clearInterval(checkIntervalId);
  });

  return (
    <>
      {confirmed ? 'Session confirmed!' : 'Waiting for confirmation...'}
    </>
  );
};
*/

const ShowOnboardingPage = () => {
  const tmpUserStore = getUserStore()

  const [userRoles, setUserRoles] = createSignal<UserRoleType[]>(UserRoleList)//createSignal(['Administrator', 'Manager', 'Viewer', 'User', 'Recruiter', 'Legal', 'Billing'])
  const [activeUserRole, setActiveUserRole] = createSignal('User')

  const [userName, setUserName] = createSignal(tmpUserStore.userId);
  const [userPassword, setUserPassword] = createSignal('');
  const [userPassword2, setUserPassword2] = createSignal('');
  const [pwMessage, setPwMessage] = createSignal('');
  const [firstName, setFirstName] = createSignal(tmpUserStore.userFirstName);
  const [lastName, setLastName] = createSignal(tmpUserStore.userLastName);
  const idxCountryCode = getCurrentUILanguageCode() as string
  interface NewUser {

    userId?: string;
    userEmail?: string;
    userFirstName?: string;
    userLastName?: string;
    userPassword?: string;
    userPassword2?: string;
    userMobilePrefix?: string;
    userMobile?: string;
    userCompanyId?: string;
    userCompanyName?: string;
    userLanguage?: string;
    userCountry?: string;
    billingEmail?: string;
    userCustomerView: boolean;
    userCandidateView: boolean;
    userBrookerView: boolean;
    userSupplierView: boolean;
  }
  interface NewCompany {
    companyId?: string;
    companyWww?: string;
    companyName?: string;
    companyNumber: string;
    companyCountry?: string;

  }
  interface OnBoard {
    user: NewUser;
    company: NewCompany;
    billingEmail?: string;
  }

  const [newUserStore, setNewUserStore] = createStore<OnBoard>({
    user: {
      userId: tmpUserStore.userId as string,
      userEmail: tmpUserStore.userId as string,
      userFirstName: tmpUserStore.userFirstName as string,
      userLastName: tmpUserStore.userLastName as string,
      userPassword: "",
      userPassword2: "",
      userCompanyId: "",
      userCompanyName: "",
      userMobilePrefix: "",
      userMobile: "",
      userLanguage: (navigator.language || navigator['language']) as string,
      userCountry: idxCountryCode as string,
      userCustomerView: false,
      userCandidateView: false,
      userBrookerView: false,
      userSupplierView: false
    },
    company: {
      companyId: "",
      companyWww: "",
      companyName: "",
      companyNumber: "",
      companyCountry: "",
    },
    billingEmail: "",
  });


  const [inviteMail, setInviteMail] = createSignal();
  const [inviteFirstName, setInviteFirstName] = createSignal();
  const [inviteLastName, setInviteLastName] = createSignal();
 
  const [language, setLanguage] = createSignal('');
  const [uiLanguage, setUILanguage] = createSignal('');
  const [uiLanguageOptions, setUILanguageOptions] = createSignal<UILanguageType[]>();

  const [browserLanguage, setBrowserLanguage] = createSignal(navigator.language || navigator['language']);
  const handleChangeUILanguage = (event: SelectChangeEvent) => {

    const newValue = event.target.value;
    setUILanguage(newValue)

  }
  const txtNoMatch = getText("confirmpassword", "nomatch")
  let checkMatch = () => {
    (userPassword() === userPassword2()) ? setPwMessage('') : setPwMessage(txtNoMatch() as string);
  }
  const handleChangePassword = (pw: string) => {
    setUserPassword(pw)
    setNewUserStore( "user", {userPassword: pw })
    checkMatch()
  }
  const handleChangePassword2 = (pw: string) => {
    setUserPassword2(pw)
    setNewUserStore( "user", {userPassword2: pw })
    checkMatch()
  }
  const [activeTab, setActiveTab] = createSignal<number>(0)

  const handleChangeUserRole = (event: SelectChangeEvent) => {

    const newValue = event.target.value;
    setActiveUserRole(newValue)
  };

  const handleClickSubmit = () => {
    setActiveTab(activeTab()<4 ? activeTab()+1 : 4)
  }
  const onClickCustomer = () => {
    setNewUserStore( "user", {userCustomerView: newUserStore.user.userCustomerView===true ? false : true})
  }
  const onClickCandidate = () => {
    setNewUserStore( "user", {userCandidateView: newUserStore.user.userCandidateView===true ? false : true})
  }


  onMount(() => {
    let language = navigator.language || navigator['language'] || "";

    const suggestedUILanguages = UILanguages.filter(item => item.active);
    setUILanguageOptions(suggestedUILanguages);
 
    setNewUserStore( "company", {companyCountry: language })

    //  const tmpUserStore = getUserStore()
    //  setUserName(tmpUserStore.userId)
    //  setFirstName(tmpUserStore.userFirstName)
    //  setLastName(tmpUserStore.userLastName)
  });
  const GetFlag = (flagCode: string, width: string) =>{
    return (

      <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/${width}/${flagCode.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${flagCode.toLowerCase()}.png 2x`}
        alt=""
      />

    )
  }
  const txtHeader = getText("onboard", "header", [{ name: 'email', value: userName() }])
  const txtDetailsTab = getText("onboard", "tabdetails")
  const txtPasswordTab = getText("onboard", "tabpassword")
  const txtCompanyTab = getText("onboard", "tabcompany")
  const txtViewsTab = getText("onboard", "tabviews")
  const txtInviteTab = getText("onboard", "tabinvite")
  const txtMobile = getText("contact", "mobilenumber")
  const txtFirstname = getText("contact", "firstname")
  const txtLastname = getText("contact", "lastname")
  const txtCountry = getText("contact", "country")
  const txtLanguage = getText("onboard", "language")
  const txtUILanguage = getText("onboard", "uilanguage")
  const txtViewAction = getText("onboard", "viewsaction")
  const txtCandidate = getText("default", "candidate")
  const txtCustomer = getText("default", "customer")
  const txtSupplier = getText("default", "supplier")
  const txtBroker = getText("default", "broker")
  const txtConfirmPassword = getText("confirmpassword", "rule")
  const txtPassword = getText("contact", "password")
  const txtReenterPassword = getText("confirmpassword", "reenterpassword")
  const txtCompanyTitle = getText("onboard", "companyaction", [{ name: "userName", value: userName() }])
  const txtCompanyWww = getText("contact", "companywww")
  const txtCompanyNumber = getText("contact", "companynumber")
  const txtCompanyName = getText("contact", "companyname")
  const txtCompanyCountry = getText("onboard", "legalcountry")
  const txtInvoiceEmail = getText("onboard", "invoicecopy")
  const txtInviteAction = getText("onboard", "inviteaction")
  const txtEmail = getText("contact", "email")
  const txtRole = getText("onboard", "role")
  const txtSendInvite = getText("onboard", "sendinvite")
  const txtContinue = getText("onboard", "continue")
  const txtSubmit = getText("onboard", "submit")
 

  return (
    <>

      <AppBarWithLogo />


      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>
        <Box sx={{ margin: 5, width: 1200, bgcolor: 'background.default'[200] }}>

          {/* ****************** */}
          {/* Onboard main part */}

          <ShowHeading pxValue={2} > {txtHeader()} </ShowHeading>


          <Divider />

          <Card sx={{ margin: 2, display: 'flex' }} >


            <Grid container spacing={2}>

              <Grid item xs={2} md={4} container alignItems="center" justifyContent="center">

                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                    <CardMedia
                      component="img"
                      sx={{ maxWidth: '100%', maxHeight: '100%' }}
                      image="/assets/imgs/signup/08.png"
                      alt="Live from space"
                    />
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={10} md={8}  >

                <Stack direction="column" alignItems="left" spacing={2} sx={{ margin: 2, display: 'flex' }} >
                  <Tabs activeTab={activeTab()} setActiveTab={setActiveTab}>
                    <Tab >{txtDetailsTab}</Tab>
                    <Tab >{txtPasswordTab()}</Tab>
                    <Tab >{txtCompanyTab()}</Tab>
                    <Tab >{txtViewsTab()}</Tab>
                    <Tab >{txtInviteTab()}</Tab>
                  </Tabs>

                  {/* Recommendations */}
                  <Card sx={{ display: 'flex' }} >
                    <Stack direction="column"  >

                      {/* Details */}
                      <Show
                        when={activeTab() === 0}
                      >
                        <Card sx={{ display: 'flex', margin: 2, }} >
                          <Stack direction="column" alignItems="left" margin={2} spacing={2}>


                            <Stack direction="row" alignItems="left" spacing={1}  >


                              <FieldTextValue
                                id="firstName" label={txtFirstname()} textValue={newUserStore.user.userFirstName as string} width={230}
                                valueOnChange={value => setNewUserStore( "user", {userFirstName: value })}
                              />
                              <FieldTextValue
                                id="lastName" label={txtLastname()} textValue={newUserStore.user.userLastName as string} width={230}
                                valueOnChange={value => setNewUserStore( "user", {userLastName: value })}
                              />
                            </Stack>

                            <CountrySelect defaultValue={newUserStore.user.userCountry as string} id="contryId" label={txtCountry()} type="Country" valueOnChange={value => setNewUserStore( "user", {userCountry: value })} />

                            <CountrySelect defaultValue={'da'} id="languageId" label={txtLanguage()} type="Language" valueOnChange={value => setNewUserStore( "user", {userLanguage: value })} />
 
                            <PhoneNumberInput id="mobileNoId" label={txtMobile()} 
                              startPrefix={newUserStore.user.userMobilePrefix ? newUserStore.user.userMobilePrefix : getMobilePrefixFromCountryCode(newUserStore.user.userCountry as string)} 
                              startValue={newUserStore.user.userMobile as string}
                              prefixOnChange={value => setNewUserStore( "user", {userMobilePrefix: value })}
                              valueOnChange={value => setNewUserStore( "user", {userMobile: value })} />

                          </Stack>
                        </Card>
                      </Show>


                      {/* Views */}
                      <Show
                        when={activeTab() === 3}
                      >

                        <Card sx={{ display: 'flex', margin: 2, width: '100%', height: '100%' }} >
                          <Stack direction="column" alignItems="left" spacing={0.5}  >

                            <Typography variant="subtitle1">{txtViewAction()} </Typography>

                            <FormControlLabel
                              id="isCandidate"
                              control={<Switch id="isCandidate" inputProps={{ 'aria-label': 'controlled' }} onChange={onClickCandidate} checked={newUserStore.user.userCandidateView===false ? false : true} />}
                              label={txtCandidate()}
                              sx={{ px: 2 }}
                            />
                            <FormControlLabel
                              id="isCustomer"
                              control={<Switch id="isCustomer" inputProps={{ 'aria-label': 'controlled' }} onChange={onClickCustomer} checked={newUserStore.user.userCustomerView===false ? false : true} />}
                              label={txtCustomer()}
                              sx={{ px: 2 }}
                            />
                            <FormControlLabel
                              id="isSupplier"
                              control={<Switch id="isSupplier" disabled />}
                              label={txtSupplier()}
                              sx={{ px: 2 }}
                            />
                            <FormControlLabel
                              id="isBroker"
                              control={<Switch id="isBroker" disabled />}
                              label={txtBroker()}
                              sx={{ px: 2 }}
                            />

                          <Select
                              value={uiLanguage()}
                              defaultValue={uiLanguage()}
                              label={txtUILanguage()}

                              variant="outlined"
                              size="small"
                              onChange={handleChangeUILanguage}
                            >
                              {UILanguages.map((option, index) => (
                                <MenuItem
                                  value={option.flagCode}
                                >

                                  <ListItemIcon>
                                    {GetFlag(option.flagCode, "w20")}
                                  </ListItemIcon>
                                  <ListItemText>
                                    {option.label}
                                  </ListItemText>
                                </MenuItem>
                              ))}

                            </Select>

                            </Stack>

                        </Card>
                      </Show>


                      {/* Password */}
                      <Show
                        when={activeTab() === 1}
                      >
                        <Card sx={{ display: 'flex', margin: 2, }} >
                          <Stack direction="column" margin={2} spacing={2}>
                            <Typography variant="subtitle1">{txtConfirmPassword()} </Typography>

                            <Stack direction="row" margin={2} spacing={2}>
                              <FieldPassword
                                id="pw1" label={txtPassword()} textValue={newUserStore.user.userPassword}
                                error={false}
                                helperText={" "}
                                valueOnChange={value => handleChangePassword(value)}
                              />
                              <FieldPassword
                                id="pw2" label={txtReenterPassword()} textValue={newUserStore.user.userPassword2}
                                valueOnChange={value => handleChangePassword2(value)}
                                helperText={pwMessage()}
                                error={userPassword() !== userPassword2()}
                              />
                            </Stack>
                          </Stack>
                        </Card>
                      </Show>


                      {/* Company */}
                      <Show
                        when={activeTab() === 2}
                      >
                        <Card sx={{ display: 'flex', margin: 2, }} >
                          <Stack direction="column" alignItems="left" margin={2} spacing={2}>

                            <Typography variant="subtitle1">{txtCompanyTitle() as string}</Typography>

                            <Stack direction="row" alignItems="left" spacing={1}  >
                              <FieldTextValue
                                id="wwwAdr" label={txtCompanyWww()} textValue={newUserStore.company.companyWww as string} width={230}
                                valueOnChange={value => setNewUserStore( "company", {companyWww: value })}
                              />
                              <FieldTextValue
                                id="companyNumber" label={txtCompanyNumber()} textValue={newUserStore.company.companyNumber as string} width={230}
                                valueOnChange={value => setNewUserStore( "company", {companyNumber: value })}
                              />
                              <FieldTextValue
                                id="companyName" label={txtCompanyName()} textValue={newUserStore.company.companyName as string} width={230}
                                valueOnChange={value => setNewUserStore( "company", {companyName: value })}
                              />
                            </Stack>

                            <CountrySelect id="companyContryId" label={txtCompanyCountry()} type="Country" valueOnChange={value => setNewUserStore( "company", {companyCountry: value })} />
                           
                            <IndustryList />

                            <Stack direction="row" alignItems="left" spacing={2}>

                              <FieldEmail
                                id="billingEmail" label={txtInvoiceEmail()} textValue={newUserStore.user.userEmail as string} width={230}
                                valueOnChange={value => setNewUserStore({ billingEmail: value })}
                              />
                            </Stack>
                          </Stack>
                        </Card>

                      </Show>


                      {/* Invite others */}
                      <Show
                        when={activeTab() === 4}
                      >

                        <Card sx={{ display: 'flex', margin: 2, }} >
                          <Stack direction="column" alignItems="left" margin={2} spacing={2}>

                            <Typography variant="subtitle1">{txtInviteAction()}</Typography>

                            <FieldEmail
                              id="inviteEmail" label={txtEmail()} textValue="" width={230}
                              valueOnChange={value => setInviteMail(value)}
                            />

                            <Stack direction="row" alignItems="left" spacing={1}  >


                              <FieldTextValue
                                id="firstName" label={txtFirstname()} textValue={txtFirstname() as string} width={230}
                                valueOnChange={value => setInviteFirstName(value)}
                              />
                              <FieldTextValue
                                id="lastName" label={txtLastname()} textValue={txtLastname() as string} width={230}
                                valueOnChange={value => setInviteLastName(value)}
                              />
                            </Stack>

                            <Select
                              value={activeUserRole()}
                              defaultValue={activeUserRole()}
                              label={txtRole()}
                              variant="outlined"
                              size="small"
                              onChange={handleChangeUserRole}
                            >
                              {userRoles()?.map((option, index) => (
                                <MenuItem
                                  value={option.code}
                                >
                                  <ListItemText>
                                    {option.label[idxCountryCode as keyof typeof option.label] as string}
                                  </ListItemText>
                                </MenuItem>
                              ))}

                            </Select>

                            <Button variant="outlined">{txtSendInvite()}</Button>
                          </Stack>
                        </Card>
                      </Show>

                      <Divider />


                      <DialogActions>
                        <Button onClick={handleClickSubmit} variant="contained" type="submit" >{activeTab() < 4 ? txtContinue() : txtSubmit()}</Button>
                      </DialogActions>

                    </Stack>
                  </Card>


                </Stack>


              </Grid>
            </Grid>
          </Card>
        </Box>

      </Box>
    </>
  );
}
export {ShowOnboardingPage}